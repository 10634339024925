import { PDFViewer } from "@react-pdf/renderer";
import Info from "components/Modals/Info";
import CvPdf from "components/Pdf/CvPdf";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from 'uuid';
import { setCheckedQualifications } from "../../redux/reducer/updatedCvsReducer";

// components


export default function Table({data=[], cssClass='', info=""}) {

  const [showCvPdfBtn, setShowCvPdfBtn] = React.useState(false);
  const [showCvPdf, setShowCvPdf] = React.useState(false);
  const [showHideCvBtn, setShowHideCvBtn] = React.useState(false);
  const {t}= useTranslation('site');
  const dispatch = useDispatch();
  const { cvId, jobId, cvRubricationData, jobRubricationData } = useSelector(state => state.searchInput);
  const updatedCvs = useSelector(state => state.updatedCvs);
  const updatedCv = updatedCvs.find(d => d.cvId === cvId && d.jobId === jobId);
  let checkedQualifications = updatedCv ? updatedCv.checkedQualifications : [];
  const [checkedQualificationsState,setCheckedQualificationsState] = React.useState(checkedQualifications);
  
  const checkQualificationHandler = (e, category_id, category_name, sub_category_id, sub_category_name, ranking_score) => {
    if (e.target.checked) {
      setCheckedQualificationsState([...checkedQualificationsState, { category_id, category_name, sub_category_id, sub_category_name, ranking_score, checked:true }]);
    } else {
      setCheckedQualificationsState(checkedQualificationsState.filter(d => {
        if (d.category_id === category_id) {
          return d.sub_category_name !== sub_category_name;
        }
        return true;
      }));
    }
    
  }

  console.log({checkedQualificationsState})

  // React.useEffect(() => {
  //   const updateCvBtn = document.querySelector('#updateCvBtn');
  //   updateCvBtn.dispatchEvent(new Event('click', { bubbles: true }));
  // }, []);
  let generatedCv = JSON.parse(localStorage.getItem('generatedCv'));
  const updateCvHandler = (e) => {
    //update generated cv
    
    if (generatedCv) {

      if (checkedQualificationsState.length > 0) {
        checkedQualificationsState.map(elm => {
          if (elm.category_id === 4000) {
            const oldOfficialTitles = generatedCv.officialTitles;
            const newOfficialTitle = {
              id: uuid(),
              createdAt: Date.now(),
              officialTitle: elm.sub_category_name,
              school: "",
              year: ""
            };
            const updatedSocialLinks = [...oldOfficialTitles, newOfficialTitle];
            generatedCv.officialTitles = updatedSocialLinks;
          } else if (elm.category_id === 9000) {
            const oldLanguages = generatedCv.languages;
            const newLanguage = {
                id: uuid(),
                createdAt: Date.now(),
                language: elm.sub_category_name,
                level: "",
                isNative: false
            };

            const updatedLanguages = [...oldLanguages, newLanguage];
            generatedCv.languages = updatedLanguages;
          } else {
            const oldSkills = generatedCv.skills;
            const newSkill = {
                id: uuid(),
                createdAt: Date.now(),
                skillName: elm.sub_category_name,
                rating: 3,
            };

            const updatedSkills = [...oldSkills, newSkill];
            generatedCv.skills = updatedSkills;
          }
        });


        localStorage.setItem('generatedCv', JSON.stringify(generatedCv));
        setShowCvPdfBtn(true);
      }


    }
    
    const newCvRubrications = [...cvRubricationData, ...checkedQualificationsState];
    


    const notCheckedQualifications = data.filter(elm => {
      const item = checkedQualificationsState.find(c => c.category_id === elm.category_id && c.sub_category_id === elm.sub_category_id);
      if (item || elm.category_id === 9000 || elm.category_id === 9300) {
        return false;
      }
      return true;
    });

    const plusText = notCheckedQualifications.reduce((prev, curr) => {
      const item = jobRubricationData.find(cv=>cv.sub_category_id === curr.sub_category_id)
      if (prev === '') {
        return item.sub_category_name;
      }
      return prev + ', ' + item.sub_category_name;
      
    }, "");

    dispatch(setCheckedQualifications(cvId, jobId, checkedQualificationsState, newCvRubrications,plusText));
    
  }

  const previewCv = () => {

    setShowCvPdf(true);
    setShowCvPdfBtn(false);
    setShowHideCvBtn(true);
  }
  const hideCv = () => {
    setShowCvPdf(false);
    setShowCvPdfBtn(true);
    setShowHideCvBtn(false);
  }

  const [isOpen, setIsOpen] = useState(true)

  
  return (
    <>
    <div className={cssClass}>
      <div
        className="relative flex flex-col min-w-0 break-words w-full mb-6  bg-white "
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1 flex justify-between items-center">
              <h3
                className="text-lg text-blueGray-700 flex gap-2 items-center" 
              >
                <span className="text-2xl">2</span>
                {t('Add to Job possible?')}
                {info && <Info  msg={info} />}
                </h3>
                <button onClick={()=>setIsOpen(!isOpen)} > <i className={`fa text-xl ${isOpen ? "fa-chevron-down" : "fa-chevron-right"}`}></i></button>
            </div>
          </div>
        </div>
        {isOpen && <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <p className="px-4 py-2">{t('Missing QUALIFICATIONS')}</p>
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className="px-6 align-middle  py-3 text-xs uppercase  whitespace-nowrap font-semibold text-left " 
                >
                  {t('CATEGORIES_Menu')}
                </th>
                <th
                  className="px-6 align-middle  py-3 text-xs uppercase  whitespace-nowrap font-semibold text-left " 
                >
                  {t('QUALIFICATIONS_Menu')}
                </th>
                <th
                  className="px-6 align-middle  py-3 text-xs uppercase  whitespace-nowrap font-semibold text-left " 
                >
                  {t('To be upskilled')}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((d,index) => {
                const { ranking_score, category_name, sub_category_name, category_id,sub_category_id } = d;
                const checkedItem = checkedQualificationsState.find(c => c.sub_category_name === sub_category_name && c.category_id === category_id);
                const checked = checkedItem ? true : false;
                return (
                  <tr key={index}>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                      {category_name}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {sub_category_name}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <input type="checkbox" checked={checked} onChange={(e) => checkQualificationHandler(e, category_id, category_name, sub_category_id, sub_category_name, ranking_score)} />
                    </td>
                  </tr>
                );
              }
              )}
              
              
            </tbody>
          </table>
          
        </div>}
      </div>
      {isOpen && <>
        <button id="updateCvBtn" className="w-full bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-4 py-2  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mb-5 float-right" onClick={updateCvHandler}>{t('APPLY_Btn')}</button>
        {showCvPdfBtn && <button id="updateCvBtn" className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-sm px-4 py-2  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mb-5 mx-5 float-right" onClick={previewCv} >Preview CV</button>}
        {showHideCvBtn && <button id="updateCvBtn" className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-sm px-4 py-2  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mb-5 mx-5 float-right" onClick={hideCv} >HIDE CV</button>}
      </>}
      </div>
      {showCvPdf && isOpen && <div
        className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white "
      >
        <div className="w-full lg:w-12/12">
                <PDFViewer  style={{width:  "100%", height:  1000}}>
                  <CvPdf formData={generatedCv} />
                </PDFViewer>
            </div>
      </div>}
    </>
  );
}

