import React from 'react';
import axiosClient from "api/axiosClient";
import UploadCV from "components/Modals/UploadCV"
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import JobLoading from '../../Loadings/jobLoading';
import { setJobId, setJobTitle } from 'redux/reducer/searchReducer';
import { setTrainingId } from 'redux/reducer/searchReducer';
import { addTrainingList } from 'redux/reducer/trainingListReducer';
import { setTrainingRubricationData } from 'redux/reducer/searchReducer';
import { setShowLoader } from 'redux/reducer/searchReducer';
import { setJobRubricationData } from 'redux/reducer/searchReducer';
import missingItems from "utils/missingItems";
import { setCheckedQualifications } from 'redux/reducer/updatedCvsReducer';
import { checkJobOption } from 'redux/reducer/jobOptionsReducer';
import { clearCheckedJobOptions } from 'redux/reducer/jobOptionsReducer';
import { addJob } from 'redux/reducer/jobListReducer';
import { addJobOptions } from 'redux/reducer/jobOptionsReducer';
import Info from 'components/Modals/Info';

const SelectJob = () => {
    const {t,i18n}=useTranslation('site');
    let lang = i18n.language;
    lang = i18n.language.substring(0, 2).toUpperCase();
    const dispatch = useDispatch();
    const isUpdatedCv  = useSelector(state=> state.isUpdatedCv);
    let {
        cvId,
        jobId,
        resultNumber, 
        cvRubricationData
    } = useSelector(state => state.searchInput, shallowEqual);
    const [showJobLoader, setShowJobLoader] = React.useState(false);
    // const [selectedJobs, setSelectedJobs] = React.useState([]);
    const updatedCvs = useSelector(state => state.updatedCvs, shallowEqual);
    const jobList = useSelector(state => state.jobList, shallowEqual);
    const jobOptions = useSelector(state => state.jobOptions, shallowEqual);
    const selectedJobs = (jobOptions && jobOptions.length > 0) ? jobOptions.filter(job => job.checked): [];
    const jobs = jobList.find(j => j.cvId === cvId);
    const resultLimit =  resultNumber > 0 ? resultNumber : undefined
    const plusTextCv = cvRubricationData.sort((a, b) => b.ranking_score - a.ranking_score).slice(0, resultLimit).filter((el) => el.checked).map(rubrication => rubrication.sub_category_name).join(', ');

    const selectJobHandler = (e) => {
        const selectedjobId = e.target.value;
        
        dispatch(setJobId(selectedjobId));
        dispatch(setJobTitle(e.target?.selectedOptions[0]?.text));
        getJobRubrication(selectedjobId);
        dispatch(setTrainingId(''));
        dispatch(addTrainingList([]));
        dispatch(setTrainingRubricationData([]));
        // if (selectedjobId && showTraining) {
        //     trainingMatchingHandler(cvId,selectedjobId)
        // }
        
    }

    const getJobRubrication = (jobId) => {
        if (jobId) {
            dispatch(setShowLoader(true));
            axiosClient.get(`/Rubrication/GetCVRubricationById?cvId=${jobId}`).then(res => {
                if (res.status === 200) {
                    const { rubrication_items } = res.data;
                    dispatch(setJobRubricationData(rubrication_items));
                    const updatedCv = updatedCvs.find(d => d.uniqueId === cvId + jobId);
                    if (updatedCv?.uniqueId!==cvId+jobId) {
                        const sortedJobRubrications = rubrication_items.sort((a, b) => b.ranking_score - a.ranking_score);
                        const jobQualifications = resultNumber > 0 ? sortedJobRubrications.slice(0, 10) : sortedJobRubrications;
                        const missingInCv = missingItems(jobQualifications, cvRubricationData);

                        const missingInCvUpdated = missingInCv.filter(elm => elm.category_id !== 9000 && elm.category_id !== 9300);

                        const plusText = missingInCvUpdated.reduce((prev, curr) => {
                            if (prev === '') {
                                return curr.sub_category_name;
                            }
                            return prev + ', ' + curr.sub_category_name;
                        
                        }, "");
                        dispatch(setCheckedQualifications(cvId, jobId, [], cvRubricationData,plusText));
                    }
                }
                dispatch(setShowLoader(false));
            }).catch(err => dispatch(setShowLoader(false))); 
        } else {
            dispatch(setJobRubricationData([]));
            dispatch(setShowLoader(false));
        }
        
    }

    const handleJobOption = (index, target_profile_id, title, checked) => {
        dispatch(checkJobOption(index, !checked))
        if (checked && jobId === target_profile_id && selectedJobs.length> 1) {
            const updatedSelectedJobList = selectedJobs.filter(j => j.target_profile_id !== target_profile_id);
            const nextJob = updatedSelectedJobList[0];
            dispatch(setJobId(nextJob.target_profile_id));
            document.querySelector('#job_list').value = nextJob.target_profile_id;
            document.querySelector('#job_list').dispatchEvent(new Event('change', { bubbles: true }));
            
        }
        // if (checked === false) {
        //     setSelectedJobs([...selectedJobs, {
        //         id: target_profile_id,
        //         title
        //     }]);
        // } else {
            
        //     const updatedSelectedJobs = selectedJobs.filter(j => j.id !== target_profile_id);
        //     setSelectedJobs(updatedSelectedJobs);
        // }
    }

    const removeSelectedJob = (id) => {
        // const updatedSelectedJobs = selectedJobs.filter(j => j.id !== id);
        const index = jobOptions.findIndex(j => j.target_profile_id === id);
        dispatch(checkJobOption(index, false));
        // setSelectedJobs(updatedSelectedJobs);
        
    }
    const clearJobOptions = () => {
        // setSelectedJobs([]);
        dispatch(clearCheckedJobOptions());
    }
    const findOptimizedJobs = () => {

        
        dispatch(setShowLoader(true));
        setShowJobLoader(true);

        const selected_job_ids = selectedJobs.map(j => j.target_profile_id);
        const body = {
            selected_cv_ids: selected_job_ids,
            ref_profile_id: cvId,
            languages: [
                "FR","DE","EN"
            ],
            language: lang,
            fallback_language: "DE"
        };
        axiosClient.post(`/Matching/GetOptimizedCVMatch`, body).then(res => {
            if (res.status === 200) {
                if (res.data && res.data.length) {
                    let jobs = {
                        cvId,
                        jobList: []
                    };
                    let firstJobId = '';
                    res.data.map((data, index) => {
                        const { title, company, target_profile_id, ranking_score, city, position } = data;
                        const job = {
                            title,
                            company,
                            target_profile_id,
                            ranking_score,
                            city,
                            position
                        }
                        jobs.jobList.push(job);
                        if (index === 0) {
                            firstJobId = target_profile_id;
                            
                        } 
                        
                    })
                    dispatch(addJob(jobs));
                    dispatch(setJobId(firstJobId));
                    const select_job = document.querySelector('#job_list');
                    select_job.value = firstJobId;
                    select_job.dispatchEvent(new Event('change', { bubbles: true }));
                    
                } 
            } else {
                dispatch(setShowLoader(false));
            }
            setShowJobLoader(false);
        }).catch(err => {
            dispatch(setShowLoader(false));
        });
    }

    const handleSubmit = () => {
        if (!jobId) {
            if (jobs && jobs.jobList.length > 0) {
                dispatch(setJobId(jobs.jobList[0].target_profile_id))
                const select_job = document.querySelector('#job_list');
                select_job.value = jobs.jobList[0].target_profile_id;
                select_job.dispatchEvent(new Event('change', { bubbles: true }));

            } else {
                jobMatchingHandler(cvId);
            }
        }
    }

    const jobMatchingHandler = (cvId) => {
        const selectedJobs = jobList.find(item => item.cvId === cvId);

        if (!selectedJobs ) {
            findJobs(cvId);
        }
    }

    const findJobs = (cvId) => {
        dispatch(setShowLoader(true));
        setShowJobLoader(true);
        const body = {
            ref_profile_id: cvId,
            languages: [
                "EN","DE","FR"
            ],
            language: lang,
            fallback_language: "DE"
        };
        axiosClient.post(`/Matching/GetCVMatch`, body).then(res => {
            if (res.status === 200) {
                if (res.data && res.data.length) {
                    let jobs = {
                        cvId,
                        jobList: []
                    };
                    let firstJobId = '';
                    res.data.filter(j => j.title ).map((data, index) => {
                        const { title, company, target_profile_id, ranking_score, city, position } = data;
                        const job = {
                            title,
                            company,
                            target_profile_id,
                            ranking_score,
                            city,
                            position
                        }
                        jobs.jobList.push(job);
                        if (index === 0) {
                            firstJobId = target_profile_id;
                        } 
                        
                        
                    })
                    dispatch(addJob(jobs));
                    dispatch(addJobOptions(jobs.jobList));
                    dispatch(setJobId(firstJobId));
                    dispatch(checkJobOption(0, true))
                    const select_job = document.querySelector('#job_list');
                    select_job.value = firstJobId;
                    select_job.dispatchEvent(new Event('change', { bubbles: true }));
                    
                } 
            } else {
                dispatch(setShowLoader(false));
            }
            setShowJobLoader(false);
        }).catch(err => {
            dispatch(setShowLoader(false));
        });
    }

    React.useEffect(() => {
        if (!jobs) {
            findJobs(cvId);
        }
    }, [jobs]);

    
    
    return (
        <>
            
            <div className="px-3 w-full pt-5 flex relative">
                <Info cssClasses="absolute right-0 top-2" msg="select_job_info" />
                <div className="w-6/12">
                    <label htmlFor="selected_job_list " className="font-semibold flex">
                        {t('List of CVs')}
                        
                    </label>
                    <div className="flex flex-col  overflow-y-auto pt-2" style={{ maxHeight: '200px' }}>
                        {
                            jobOptions && jobOptions.length > 0 && jobOptions.map((job, index) => {
                                let checked = job.checked ?? false;
                                return (
                                    <label className="pr-4 flex justify-between" key={index} > {job.title} <input type="checkbox" checked={checked} className="ml-2" onChange={()=>handleJobOption(index,job.target_profile_id,job.title ,checked)} /></label>
                                )
                            })
                        }
                        
                    </div>
                </div>
                
                <div className="w-6/12 px-2 relative">
                    <label htmlFor="selected_job_list " className="font-semibold flex">
                        {t('Selected CVs')}
                    </label>
                    <div className="flex gap-1 flex-wrap overflow-y-auto" style={{ maxHeight: '160px' }}>
                        {selectedJobs.length > 0 && selectedJobs.map(job=><div key={job.target_profile_id} className="bg-lightBlue-200 py-1 px-2 rounded flex gap-2 ">{job.title} <button><i className="fas fa-times" onClick={()=>removeSelectedJob(job.target_profile_id)}></i></button></div>)}
                    </div>
                    {selectedJobs.length > 0 &&
                        <div className="flex gap-2 absolute bottom-0 right-2">
                            <button
                                className="bg-blueGray-200  active:bg-lightBlue-600 font-bold  text-sm px-3 py-1  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 "
                                onClick={()=>clearJobOptions()}
                            >
                                {t('clear')}
                            </button>
                        </div>
                    }
                </div>
                
            </div>

            <div className="flex align-center gap-5 mt-10">
                <div className="w-6/12 mx-auto">
                    <label htmlFor="job_list" className="font-semibold flex gap-1">{t('Choose a CV')}{ showJobLoader && <JobLoading  /> } </label>
                    <select
                        id="job_list"
                        className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full max-w-full " 
                        onChange={selectJobHandler}
                        value={jobId}
                    >
                        <option value=""></option>
                        {selectedJobs && selectedJobs.length > 0 && selectedJobs.map((job, index) => <option key={index} value={job.target_profile_id}>{job.title}</option>)}
                    </select>    
                </div>
                {/* <div className="w-2/12">
                    <button
                        className="w-full bg-lightBlue-500 disabled:bg-lightBlue-400 disabled:cursor-not-allowed text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-3 py-3  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-6"
                        onClick={findOptimizedJobs}
                        disabled={selectedJobs.length === 0}
                    >
                        <i className="fas fa-search"></i> {t('FIND_JOBS_Btn')}
                    </button>
                    
                </div> */}
            </div>
        </>
    )
}
export default SelectJob;