import React from 'react'
import HeaderStats from "components/Headers/HeaderStats.js";
import HorizontalBarChart from "components/Charts/HorizontalBarChart.js";
import RadarChart from "components/Charts/RadarChart.js";
import ActionButons from "components/Buttons/ActionButtons.js";
import { useSelector } from "react-redux";
import Mapper from '../../utils/mapper';
import MapperPercentage from '../../utils/mapperPercentage';
import cvMapper from 'utils/cvMapper';
import trainingMapper from 'utils/trainingMapper';
import MapChart from 'components/Charts/MapChart';
import { useTranslation } from 'react-i18next';
import translateRubrications from '../../utils/translateRubrications';






function BoostChancesSection() {
    const { t,i18n } = useTranslation('site');
    const lang = i18n.language.substring(0, 2);
    let { cvId, jobId, resultNumber, cvRubricationData, jobRubricationData, trainingRubricationData } = useSelector(state => state.searchInput);

    cvRubricationData = translateRubrications(cvRubricationData,lang);
    jobRubricationData = translateRubrications(jobRubricationData,lang);
    trainingRubricationData = translateRubrications(trainingRubricationData,lang);

    const trainingList = useSelector(state => state.trainingList);
    // const trainings = trainingList?.find(t => t.cvId === cvId && t.jobId === jobId);
    // const dataTrainingList = trainings?.trainingList;
    const dataTrainingList = trainingList;

    
    const updatedCvs = useSelector(state => state.updatedCvs);
    const updatedCv = updatedCvs.find(d => d.cvId === cvId && d.jobId === jobId);
    
    const updatedCvRubrications = updatedCv ? updatedCv.cvRubrications.sort((a, b) => b.ranking_score - a.ranking_score) : [];

    const sortedTrainingRubrications = trainingRubricationData.sort((a, b) => b.ranking_score - a.ranking_score);
    const trainingQualifications = resultNumber > 0 ? sortedTrainingRubrications.slice(0, 10) : sortedTrainingRubrications;
    const trainingQualificationsData = {
        label: t('Scores_Training'),
        data: trainingQualifications,
        backgroundColor: 'rgb(241, 106, 29)'
    }

    

    const trainingQualificationsHeader = resultNumber > 0 ? t('TOP_10_Score_Training') : t('all_Score_Training');
  

    const sortedJobRubrications = jobRubricationData.sort((a, b) => b.ranking_score - a.ranking_score);
    const jobQualifications = resultNumber > 0 ? sortedJobRubrications.slice(0, 10) : sortedJobRubrications;
    
    
  
    const height = resultNumber === '' ? 250 : 100;
    

    
    const updatedJobCvMapping = Mapper(jobQualifications, updatedCvRubrications);
    const jobCvTrainingMapping = updatedCv ?trainingMapper(jobQualifications, updatedJobCvMapping, trainingRubricationData) :trainingMapper(jobQualifications, cvRubricationData, trainingRubricationData);

    const cvSocres = cvMapper(jobQualifications, cvRubricationData);
    const cvSocresData = {
        label: t('Scores_cv'),
        data: updatedCvRubrications,
        backgroundColor: 'rgb(3, 120, 220)'
    }

    const trainingSocres = cvMapper(jobQualifications, trainingRubricationData, true);
    const trainingSocresData = {
        label: t('Scores_Training'),
        data: trainingSocres,
        backgroundColor: 'rgb(241, 106, 29)'
    }
    
    const jobCvMapping = Mapper(jobQualifications, cvRubricationData);

    const jobMappingPercentage = MapperPercentage(jobCvMapping);
    const updatedJobMappingPercentage = updatedCv ?MapperPercentage(updatedJobCvMapping):'N.A';
    const trainingMappingPercentage = trainingRubricationData.length>0 ?MapperPercentage(jobCvTrainingMapping):'N.A';
    const headerStatsData = [jobMappingPercentage, updatedJobMappingPercentage, trainingMappingPercentage];

    const jobMappingData = {
        label: "",
        data: updatedCv ? jobCvTrainingMapping : updatedJobCvMapping,
        backgroundColor: 'rgba(3, 194, 252,0.5)'
    }
    return (
        <>
            <HeaderStats data={headerStatsData} />
            <div className="flex flex-wrap ">
                <div className="container w-full mb-12 xl:mb-0 px-4 mx-auto">
                    <div className="flex flex-wrap">
                        <HorizontalBarChart cssClass="w-full" data={[trainingQualificationsData]} header={trainingQualificationsHeader} height={height}  showButtons={false} info="top_scores_training_info" />
                        <HorizontalBarChart cssClass="w-full" data={[cvSocresData, trainingSocresData]} header={t('Completed_CV_boosted_by_Training_Menu')} height={height} legend={true} stacked={true} showButtons={false} info="completed_cv_boosted_by_training_info" />

                        

                        <RadarChart height={200} header={t('Completed_CV_boosted_by_Training_Menu')} data={[jobMappingData]} info="completed_cv_boosted_by_training_radar_info" /> 
                        <MapChart header={t('Trainings_loaction')} data={dataTrainingList} info="training_map_info"/>

                    </div>
                </div>
            </div>
            {/* <ActionButons/> */}

        </> 
    )
}

export default BoostChancesSection